@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@500&display=swap');

.err{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    h3{
        color: #F3BA2F;
        font-size: 2.5rem;
        margin-bottom: 5px;
        
        text-transform: uppercase;
        font-family: 'Podkova',
        serif;
    }

    p{
        color: white;
        margin-bottom: 20px;
    }

    a{
        padding: 15px 30px;
        background-color: #F3BA2F;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        text-decoration: none;
        transition: 0.5s;
    }

    a:hover{
        opacity: 0.7;
    }
}