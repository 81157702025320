.confirmModal {
	width: 350px;
	// height: 300px;
	background: #221e16;
	border-radius: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.8);
	z-index: 10000;
	padding: 20px;
	visibility: hidden;
	transition: 0.3s;
	opacity: 0;

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			font-weight: 500;
			color: #ffffff99;
		}

		img {
			cursor: pointer;
		}
	}

	.lp {
		font-weight: bold;
		text-align: center;
		color: white;
		font-size: 30px;
		margin-bottom: 10px;
	}
	.pigs {
		text-align: center;
		color: #ffffff99;
		font-size: 12px;
	}

	.infoBox {
		width: 100%;
		padding: 10px;
		border-radius: 8px;
		background: #262523;
		margin-top: 20px;
	}

	.msg {
		margin-top: 20px;
		color: white;
		font-size: 12px;
		text-align: center;
	}

	button {
		width: 100%;
		height: 50px;
		margin-top: 20px;
		border-radius: 10px;
		border: none;
		opacity: 1;
		cursor: pointer;
		color: white;
		background: linear-gradient(93.41deg, #47a348 1.19%, #3d6b3d 99.16%);
	}
}

.confirmModal__active {
	visibility: visible;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
}
