.toast{
    width: 350px;
    // height: 300px;
    background: #49944b61;
    color: #47A348;
    font-weight: 800;
    text-align:center;
    border-radius: 10px;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(-20);
    z-index: 10000;
    padding: 20px;
    visibility: hidden;
    transition: 0.3s;
    opacity: 0;
}

.toast__active{
    visibility: visible;
    opacity: 1;
    transform:  translateY(0);
}