@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&family=Poppins&display=swap');

.box{
    height : 300px;
    max-width: 350px;
    background: #262523;
    margin-right: 10px;
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s;
}

.box:hover{
    border: 1px solid #F3BA2F;
}

.box__h3{
    margin-bottom: 10px;
    color: #F3BA2F;
    font-family: 'Poppins',sans-serif; 
    font-size: 1.2rem
    
}



.box__p{
    font-size: 0.9rem;
   
    color: rgba(217 217 212 / 90%);
    font-weight: 100;
    line-height: 1.5rem;
}

.carousel{
    /* background-color: red; */
    width: 100%;
}



.carousel__h{
    color: rgba(217 217 212 / 90%);
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-family: 'Poppins',
    sans-serif;
}





@media (min-width: 890px) {

    .carousel__container {
        margin-left: -22px;
        margin-right: -22px;

    }
}

button.rec-dot {
    background: #767676;
    box-shadow: 0 0 1px 3px rgba(7, 4, 4, 0.5);
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
    /* box-shadow: 0 0 1px 3px rgba(235, 16, 16, 0.5); */
    background: #FED900;
    box-shadow: 0 0 1px 3px rgba(7, 4, 4, 0.5);
}


.rec-arrow {
    background-color: var(--blue) !important;
    transition: all 0.3s ease;
    font-size: 1.1rem !important;
    color: white !important;
    width: 1.7rem !important;
    height: 1.7rem !important;
    min-width: 1.7rem !important;
    line-height: 1.7rem !important;

}

.rec-arrow:hover:not(:disabled) {
    transform: scale(1.2);
    border: 1px solid #FED900;
}