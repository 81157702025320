
@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@500&display=swap');

@mixin flex($dir, $align-item, $justift-content) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justift-content;
    align-items: $align-item;
}

.nav__wrap{
    display: flex;
    align-items: center;
    flex-direction: column;

    .nav{
        // width: calc(100vw - 240px);
        width: 100%;
        height: 70px;
        padding: 0px 0px 0px 60px;
        max-width: 1500px;
        

        .nav__in{
            width: 100%;
            height: 100%;
            border-bottom: 1px solid #FFFFFF33;
            // border-radius: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding: 0px 20px 0px 20px;

            
    

            h3{
                color: rgba(217 217 212 / 90%);
                margin-left: 20px;
                // font-family: 1.1rem;
                color: #F3BA2F;
                font-family: 'Podkova',
                serif;
            }

            button{
                width: 200px;
                height: 40px;
                border: none;
                border-radius: 5px;
                font-weight: bold;
                color: white;
                cursor: pointer;
                background: linear-gradient(93.41deg, #47A348 1.19%, #3D6B3D 99.16%);
                @include flex(row, center, center);
                margin-right: 20px;

                img{
                    height: 20px;
                    margin-right: 10px;
                }

            }
        }

    
    }   
}



@media screen and (max-width : 768px) {
    .nav {
        display: none;
    }
}