.landing {
	padding: 20px;
	display: flex;
	// position: relative;

	.landing__wrap {
		// padding: 0px 50px 0px 50px;
		// background-color: blue;
		width: 100%;
	}
}

body {
	// background: linear-gradient(10deg, #000000dd, #000000ef), url(../../assets/5.png);
	// background-repeat: no-repeat;
	// background-size: cover;

	// background-position: center;

	// background-image: url(../../assets/5.png);
	// background-size: auto 1000px;
	// background-size: contain;
	// background-position: center;
	// background-repeat: no-repeat;
	// background-color: rgba(24, 24, 24, 0.355);
	background: linear-gradient(10deg, #000000dd, #000000ef);
	
}

@media screen and (max-width: 481px) {
	.landing {
		padding: 0px;
	}
}
