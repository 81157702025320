.loader{

	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 2px solid green;
	border-top: 2px solid  white;
;
	animation: spin 0.5s linear infinite;
}

@keyframes spin{
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}