.info {
	width: 100%;
	justify-content: space-between;
	display: flex;
	// margin-bottom: 10px;
	padding: 10px 20px 10px 20px;

	.title {
		color: rgba(255, 255, 255, 0.6);
		font-size: 12px;
	}

	.value {
		color: #ffffffde;
		font-weight: 400;
		font-size: 14px;
		text-transform: capitalize;
	}
}
