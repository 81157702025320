.button {
	height: 50px;
	width: 100%;
	background: rgba(225, 225, 225, 0.06);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px 0px 20px;
	margin-bottom: 15px;
	transition: 0.5s;
	cursor: pointer;
}

.button:hover {
	filter: opacity(50%);
}

.button p {
	font-weight: 600;
	color: white;
}

.icon {
	width: 30px;
}
