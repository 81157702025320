.walletModal{
    width: 400px;
    /* height: 500px; */
    background-color: #242424;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(0.8);
    position: fixed;
    border-radius: 10px;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    z-index: 10000;
}

.walletModal__active{
    visibility: visible;
    transform: translate(-50%,-50%) scale(1);
    opacity: 1;
}

.walletModal__p{
    color:#FCFCFC99;

    font-weight: 700;

}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

}

.header img{
    height: 20px;
}

@media screen and (max-width:481px) {
    .walletModal{
        width: 95%;
    }
}