@import '../../global.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.home__wrap {
	display: flex;
	justify-content: center;

	body {
		// background-image: url(../../assets/5.png);
		// background-size: auto 1000px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-color: rgb(24, 24, 24);
	}

	.home {
		// width: calc(100vw - 340px);
		padding-left: 80px;
		padding-right: 60px;
		padding-top: 50px;
		padding-bottom: 50px;
		// height: 100vh;
		max-width: 1500px;

		.home__farm {
			display: flex;

			.home__farm__one {
				width: 50%;
				h3 {
					color: rgba(217 217 212 / 90%);
					margin-bottom: 30px;
					font-size: 1.6rem;
					font-family: $boldFont;
					font-weight: 600;
				}
				p {
					color: rgba(247, 247, 218, 0.9);
					line-height: 30px;
					font-size: 1.1rem;
					font-family: 'Poppins', sans-serif;
					font-weight: 400;
				}
			}
			.home__farm__two {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				img {
					height: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {

	.home__wrap {
        
		.home {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;

			.home__farm {
				flex-direction: column;

				.home__farm__one {
					width: 100%;
				}
				.home__farm__two {
					width: 100%;
					justify-content: center;
					// background : green;
					padding: 20px 0px 20px 0px;

					img {
						height: 200px;
						width: 100%;
						max-width: 500px;
						// object-fit: contain;
					}
				}
			}
		}
	}
}
