.backdrop{
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.728);
    position: fixed;
    top: 0;
    z-index: 20;
    display: none;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}

.backdrop__active{
    display: block;
}