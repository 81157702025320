$gold: #f3ba2f;

.mobile {
	height: 100vh;
	width: 100%;
	background: #121212;
	display: none;
	position: fixed;
	padding: 20px;
	top: 0;
	left: 0;
	visibility: hidden;
	transform: translateX(-100%);
	transition: 0.5s;
	z-index: 30;

	header {
		display: flex;
		justify-content: space-between;
		h3 {
			color: $gold;
			font-weight: bold;
		}

		svg {
			color: white;
			font-size: 30px;
		}
	}

	ul {
		color: white;
		list-style: none;
		margin-top: 50px;

		.link {
			padding: 0px 0px 0px 0px;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: 0.3s ease;
			color: rgba(217 217 212 / 90%);
			text-decoration: none;
			margin-bottom: 30px;

			p {
				font-size: 14px;
				cursor: pointer;
			}

			img {
				height: 18px;
				margin-right: 10px;
				width: 18px;
			}

			svg {
				color: white;
				margin-right: 15px;
				font-size: 20px;
			}
		}

		.link:focus {
			// border-left: 3px solid #F3BA2F;
		}

		.link:focus svg {
			color: #f3ba2f;
		}

		.link:focus p {
			color: #f3ba2f;
		}

		.link__active {
			// border-left: 3px solid #F3BA2F;
		}

		.link__active svg {
			color: #f3ba2f;
		}

		.link__active p {
			color: #f3ba2f;
		}

		.settings__wrap {
			padding-left: 0px;
			.settings {
				padding: 30px 0px 0px 0;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: 0.3s ease;
				color: white;
				text-decoration: none;
				margin-bottom: 35px;
				transition: 0.3s;
				font-family: 'Poppins', sans-serif;
				border-top: 1px solid #d9e0e83d;

				.switch {
					width: 35px;
					height: 18px;
					border-radius: 10px;
					background: #d9e0e899;
					padding: 2px;
					margin-right: 10px;

					.switch__button {
						width: 50%;
						height: 100%;
						border-radius: 100%;
						background-color: black;
						transition: 0.3s ease;
					}

					.switch__button__active {
						transform: translateX(100%);
						background-color: #f3ba2a;
					}
				}

				p {
					font-size: 0.8rem;
					cursor: pointer;
					font-weight: 400;
					color: #d9e0e899;
					line-height: 1.6rem;
				}
			}
		}
	}
	button {
		background: linear-gradient(93.41deg, #47a348 1.19%, #3d6b3d 99.16%);
		padding: 10px;
		border: none;
		color: white;
		font-weight: bold;
		border-radius: 5px;
	}
}

.mobile__active {
	visibility: visible;
	transform: translateX(0%);
}

@media screen and (max-width: 768px) {
	.mobile {
		display: block;
		overflow-y: scroll;
	}
}
