$gold : #F3BA2F;

.mobilenav{

    display : none;
    color: white;
    z-index: 30;

    nav{

        .log{
            display: flex;
            align-items: center;

            img{
                height: 20px;
                margin-right: 10px;
            }
        }

        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        padding: 0px 20px 0px 20px;

        h3{
            color: $gold;
            }
        svg{
            color: white;
            font-size: 25px;
        }
    }

    
}

@media screen and (max-width:768px){

    .mobilenav{

       display: block; 
    }
    
}