.infobody {
	justify-self: center;
	margin: 0 auto !important;
	z-index: 50;
	background-color: #1a1a1a;
	position: fixed;
	overflow: scroll;
	overflow-x: hidden !important;
	border-radius: 16px;
	max-width: 1000px;
	height: 85vh !important;
	left: 0 !important;
	right: 0 !important;
	padding-bottom: 20px !important;

	h1 {
		color: #f3ba2f;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		margin-top: 37px;
		margin-bottom: 52px !important;
	}
	.faq__body {
		padding-left: 28px;
		padding-right: 28px;
	}

	h2 {
		color: rgba(255, 255, 255, 0.774);
	}
	p {
		margin-bottom: 24px;
		color: #ffffff89;
		line-height: 1.6;
	}
	.icon {
		text-align: right;
		position: absolute;
		right: 0;
		top: 0;
		margin-top: 42px;
		margin-right: 16px !important;
		cursor: pointer;
	}
}

.infobody__disable {
	display: none;
}

@media(max-width: 1000px) {
	.infobody {
		margin-left:20px !important;
		margin-right: 20px !important;
	}
	
}
