@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@500&display=swap');

.migrate{
    padding-left: 80px;
    padding-top: 60px;
    display: flex;
    height: 100vh;
    position: relative;

    flex-direction: column;
    align-items: center;
    .migratep{
        font-size: 2.1rem;
        color: #F3BA2F;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 800;
        font-family: 'Podkova',
        serif;
    }
    .migratep2{
        color: #FFFFFFDE;
        font-size: 1.5rem;
        margin-bottom: 40px;
        text-align: center;
    }

    .migratep3{
        width: 50%;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 20px;
        font-style: italic;
    }

    .p4{
        margin-bottom: 20px;
    }

    img{
        height: 60px;
    }

    #curve{
        height: 40%;
        position: fixed;
        bottom: 0px;
        // right: 50%;
    }
}

@media screen  and (max-width:1024px) {
    .migrate{
        padding-left: 20px;padding-right: 20px;

        .migratep{
            font-size: 1.5rem;
        }

        .migratep3{
            width: 100%;
        }

        #curve {
            height: 30%;
            position: fixed;
            bottom: 0px;
            // right: 50%;
        }
    }
}